<template>
  <v-container class="general">
    <PageTitle :title="'Venues'" />
    <transition name="fade" mode="out-in">
      <v-layout
        v-if="!isLoading && !venues.length"
        class="row wrap text-center"
      >
        <v-flex class="xs12 sw-h5 font-weight-light">
          {{ $t("venue_list_page.nothing_created") }}
        </v-flex>
      </v-layout>
    </transition>
    <v-layout v-if="venues && venues.length" class="row wrap">
      <v-flex class="xs12">
        <v-card class="px-4 py-3 border-radius">
          <v-layout
            v-for="(venue, i) in venues"
            :key="`venue-${i}`"
            :style="{ width: '100%' }"
            class="row wrap align-center"
          >
            <v-flex v-if="i !== 0" class="xs12 my-3">
              <v-divider></v-divider>
            </v-flex>
            <v-flex class="xs10">
              <a class="theme-primary" @click.stop="editVenue(venue.id)">{{
                venue.name
              }}</a>
              <div class="caption grey--text">
                ({{ venue.address || $t("venues.no_address") }})
              </div>
            </v-flex>
            <v-flex class="xs2 text-xs-right">
              <v-btn icon class="ma-0" @click.stop="deleteVenue(venue.id)">
                <font-awesome-icon class="theme-danger" icon="trash" />
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex v-if="totalPages > 1" class="xs12 pt-4 text-xs-center">
        <v-pagination
          v-model="page"
          :length="totalPages"
          :total-visible="totalVisible"
          @input="listGroupVenues"
        ></v-pagination>
      </v-flex>
    </v-layout>
    <AppSpeedDial
      @click="$router.push({ name: 'venues_create' })"
      :hint="'Create new venue'"
    />
    <ConfirmationDialog ref="confirmationDialog" />
  </v-container>
</template>

<script>
export default {
  data: () => ({
    isLoading: false,
    venues: [],
    page: 1,
    perPage: 16,
    totalPages: null,
    totalVisible: 5,
  }),
  mounted() {
    this.page = this.currentPage;
    this.listGroupVenues();
  },
  methods: {
    listGroupVenues() {
      const params = {
        page: this.page,
        per_page: this.perPage,
      };

      this.isLoading = true;

      this.$api.groupVenues.list(params).then(
        (response) => {
          this.isLoading = false;

          if (!response) return;

          this.venues = response.data.data;
          this.totalPages = response.data.pagination.total_pages;
          this.query();
        },
        () => {
          this.isLoading = false;
        },
      );
    },
    editVenue(id) {
      this.$router
        .push({
          name: "venues_edit",
          params: {
            venue_id: id,
          },
        })
        .catch(() => {});
    },
    async deleteVenue(id) {
      if (!id) return;

      const isConfirmed = await this.$refs.confirmationDialog.open(
        "Are you sure you want to delete this venue",
        this.$t("no"),
        this.$t("yes"),
      );

      if (!isConfirmed) return;

      this.$api.groupVenues.delete(id).then(
        (response) => {
          this.$store.dispatch("addNotification", {
            message: "Venue deleted",
          });

          this.page = 1;
          this.listGroupVenues();
        },
        (error) => this.errorMessageShow(error),
      );
    },
    query() {
      this.$router
        .replace({
          query: Object.assign({}, this.$route.query, {
            page: this.page,
          }),
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss"></style>
